import React from "react";
import Algorithm from "../assets/images/spacialAlgorithem.png";
import Rule from "../assets/images/Ruls.png";
import Flexibility from "../assets/images/Flexibility.png";
const Solution_data = [
  {
    id: 1,
    img: Algorithm,
    title: "Special Algorithm",
    desc: "Customer Genius automatically detects discount abusers trying to register",
  },
  {
    id: 2,
    img: Rule,
    title: "Your rules",
    desc: "You can be notified to manually cancel the order or set rules to automatically cancel it.",
  },
  {
    id: 3,
    img: Flexibility,
    title: "Flexibility",
    desc: "You have flexibility to be as strict as you want by determining your own rules",
  },
];
export default function PowerfulSolution({id}) {
  return (
    <div id={id} className="w-[80%] flex flex-col items-center justify-center md:mt-28 mt-20 mb-10 md:mb-28">
      <div className="text-blue text-[12px] font-bold">HOW IT WORKS?</div>
      <div className="text-custom-gray-light md:text-5xl text-3xl text-center font-semibold md:leading-tigth py-5 ">
        Powerful Solution <br />
        Just For You
      </div>
      <div className="flex flex-row">
        <div class="grid md:grid-cols-3 gtid-cols-1 gap-5">
          {Solution_data.map((item) => {
            return (
              <div className="flex flex-col" key={item.id}>
                <div className="shadow-navcustom">
                  <img src={item.img} width={578} alt="" />
                </div>
                <div className="pt-3 ps-5">
                  <div className="text-[#212428] text-2xl font-bold">
                   {item.title}
                  </div>
                  <div className="text-[#777A7C] text-md font-normal">
                    {item.desc}
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className='flex flex-col'>
          <div className='shadow-navcustom'>
          <img src={Algorithm} width={578} alt=""/>
          </div>
        <div className='pt-3 ps-5'>
          <div className='text-[#212428] text-2xl font-bold'>Special Algorithm</div>
          <div className='text-[#777A7C] text-md font-normal'>Customer Genius automatically detects discount abusers trying to register</div>
        </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}
