import React from "react";
import Shadow from "../assets/images/s2-shadow.png";
import ShadowMobile from "../assets/images/s2-shadow-mb.png";
import Discount from "../assets/images/discount-icon.png";
import Vampire from "../assets/images/vampires-icon.png";
import losse from "../assets/images/losse-icon.png";
import fackAccount from "../assets/images/fackAccount-img.png";
import Chart from "../assets/images/chart-img.png";
const discountLeftData = [
  {
    id: 1,
    img: "Img",
    count: 3,
  },
  {
    id: 2,
    img: "Img",
    count: 4,
  },
  {
    id: 3,
    img: "Img",
    count: 6,
  },
  {
    id: 4,
    img: "Img",
    count: 13,
  },
];
const discountRightData = [
  {
    id:1,
    icon:Vampire,
    title:"Discount Vampires",
    description:"Some users, called 'discount abusers,' create multiple accounts to repeatedly use your discount.",
    img:fackAccount,
  },
  {
    id:2,
    icon:losse,
    title:"Losses",
    description:"Which causes you material loss affecting your income but there is a solution.",
    img:Chart,
  },
]
export default function TheProblem({id}) {
  return (
    <div id={id} className="sm:w-[80%] w-full lg:h-[970px] h-[1700px] flex flex-col items-center bg-black sm:rounded-[40px] mt-20 sm:px-8 text-white text-center overflow-hidden">
      <img src={Shadow} alt="" className="sm:block hidden" />
      <img src={ShadowMobile} alt="" className="sm:hidden w-full" />
      <div className="absolute pt-12 md:w-[80%] w-[90%]">
        <div className="text-white font-semibold text-4xl leading-[72px]">
          The Problem
        </div>
        <div className="text-sm text-[#B8B8B8] max-w-[394px] leading-[25px] mx-auto">
          Any Shopify account has the problems mentioned below and Customer
          Genius is here to help
        </div>
        <div className="flex lg:flex-row flex-col w-[80%] mx-auto lg:gap-8 gap-20 pt-10 pb-20  md:pt-14 md:pb-40">
          <div className="lg:w-1/2 w-full xl:px-14 px-5 pt-6 border border-[#373737] rounded-[32px] bg-custom-gradient-s2-card">
            <div className="flex flex-col items-start justify-start">
              <div className="flex flex-row items-center">
                <div className="rounded-full bg-custom-gray w-16 h-16 flex flex-row items-center justify-center">
                  <img src={Discount} width={34} height={34} alt=""/>
                </div>
                <div className="md:text-xl text-lg pl-4 font-semibold">Discounts</div>
              </div>
              <div className="text-sm font-normal text-[#B8B8B8] text-start pt-3">
                Most Shopify stores offer discounts to new users, believing the
                initial loss will be offset by gaining more clients.
              </div>
              <div className="bg-[#232425] rounded-t-3xl w-[100%] mt-10 border border-[#373737]">
                <div className="flex items-center justify-center">
                  <img src={Discount} alt="" className="my-5"></img>
                </div>
                <div className="flex flex-row md:h-[400px] h-[300px] ">
                <div className="grid grid-cols-2 md:gap-6 gap-4 mx-auto">
                  {discountLeftData.map((item) => {
                    return (
                      <div key={item.id}>
                        <div class="relative bg-[#333333] rounded-2xl border-1 border border-[#494949] xl:w-36 xl:h-36 lg:w-30 lg:h-30 md:w-40 md:h-40 w-24 h-24  flex items-center justify-center">
                          {item.img}
                          <div
                            class="absolute right-[-15px] top-[-12px] min-w-10 min-h-10 rounded-lg bg-blue flex items-center justify-center text-lg "
                            role="status"
                          >
                            {item.count}
                          </div>
                        </div>
                        <div className="flex gap-2 pt-4">
                          <div className="w-2/3">
                            <div className="border-4 border-[#595959] rounded-full"></div>
                          </div>
                          <div className="w-1/3">
                            <div className="border-4 border-[#595959] rounded-full"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex flex-col lg:justify-between">
          {
            discountRightData.map((item)=>{
              return(
                <div className="border border-[#373737] rounded-[32px] md:px-12 px-5 pt-6 bg-custom-gradient-s2-card first:mb-8">
                <div className="flex flex-row items-center ">
                  <div className="rounded-full bg-custom-gray w-16 h-16 flex flex-row items-center justify-center">
                    <img src={item.icon} width={34} height={34} alt=""/>
                  </div>
                  <div className="md:text-xl text-lg pl-4 font-semibold">
                    {item.title}
                  </div>
                </div>
                <div className="text-sm font-normal text-[#B8B8B8] text-start pt-3">
                  {item.description}
                </div>
                <div className="flex items-center justify-center pt-5">
                <img src={item.img} alt=""/>
                </div>
              </div>
              )
            })
          }
          </div>
        </div>
      </div>
    </div>
  );
}
