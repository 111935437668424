import React, { useState } from 'react'
const Testimonials = ({id}) => {
    const BlueArrow = require('../assets/images/BlueArrow.png')
    const WhiteArrow = require('../assets/images/WhiteArrow.png')
    const items = [
        {
            imgSrc: 'avatar1.jpg',
            name: 'David Johnson',
            review: 'Fantastic service and quality products! Always reliable and responsive. Highly recommend!',
        },
        {
            imgSrc: 'avatar2.jpg',
            name: 'Kate Maroon',
            review: 'I’ve been using this service for a few months now, and I couldn’t be happier! The customer support team is always responsive and helpful, and the quality of their products exceeds my expectations every time.',
        },
        {
            imgSrc: 'avatar3.jpg',
            name: 'John Doe',
            review: 'The best service I have experienced so far. They go above and beyond to meet customer needs.',
        },
        {
            imgSrc: 'avatar4.jpg',
            name: 'Jane Smith',
            review: 'Excellent quality and outstanding support. Would definitely recommend to others!',
        },
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [clickedButton, setClickedButton] = useState('next');
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        setClickedButton('next');
    };
    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? items.length - 1 : prevIndex - 1
        );
        setClickedButton('prev');
    };
    return (
        <div id={id} className='w-[80%] mt-20 flex flex-col sm:flex-row mb-28'>
            <div className='w[40%]'>
                <div className=' flex flex-col sm:pl-14 pl-0 py-20 justify-center sm:items-start items-center'>
                    <div className='text-xs text-blue font-semibold pb-3'>
                        TESTEMONIALS
                    </div>
                    <div className='text-5xl flex sm:text-start text-center font-semibold text-custom-gray-light'>
                        What do
                    </div>
                    <div className='text-5xl flex sm:text-start text-center font-semibold pb-5 text-custom-gray-light'>
                        they say?
                    </div>
                    <div className='text-gray-500'>
                        Your satisfaction is our top priority, and we are proud to share testimonials from our valued customers.
                    </div>
                </div>
            </div>
            <div className="relative lg:w-[90%] w-full max-w-3xl mx-auto mt-10">
                <div className="relative h-full w-full max-w-3xl mx-auto sm:p-4 p-0">
                    <div className="flex space-x-6 justify-center overflow-hidden">
                        <div className="flex-shrink-0 w-full bg-white rounded-lg shadow-lg sm:p-3 snap-center">
                            <div
                                className="flex transition-transform duration-500 ease-in-out"
                                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                            >
                                {items.map((item, index) => (
                                    <div
                                        key={index}
                                        className="flex-shrink-0 w-full px-4 py-6 bg-white rounded-lg"
                                    >
                                        <div className="flex items-center space-x-4">
                                            <div>
                                                <h2 className="font-semibold text-lg">{item.name}</h2>
                                                <div className="flex text-yellow-500">
                                                    <span>&#9733;</span>
                                                    <span>&#9733;</span>
                                                    <span>&#9733;</span>
                                                    <span>&#9733;</span>
                                                    <span>&#9733;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="mt-4 text-gray-600">{item.review}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute sm:bottom-4 sm:mt-0 mt-6 sm:left-14 flex space-x-2">
                    <button
                        onClick={prevSlide}
                        className={`${clickedButton === 'prev' ? 'bg-[#4C53FF]' : 'bg-blue-600'
                            } text-white rounded-full px-3 py-3 shadow-lg`}
                    >
                        <img className={`${clickedButton === 'next' ? '' : 'transform rotate-180'} w-6`} src={clickedButton === 'next' ? BlueArrow : WhiteArrow} alt="" />
                    </button>
                    <button
                        onClick={nextSlide}
                        className={`${clickedButton === 'next' ? 'bg-[#4C53FF]' : 'bg-blue-600'
                            } text-white rounded-full px-3 py-3 shadow-lg`}
                    >
                        <img className={`${clickedButton === 'next' ? '' : 'transform rotate-180'} w-6`} src={clickedButton === 'next' ? WhiteArrow : BlueArrow} alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Testimonials;
