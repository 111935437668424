import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Header from './components/Header';
import Footer from './components/Footer';
import TermsandConditions from './Pages/TermsandConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
const App = () => {
  return (
    <div className='flex justify-center items-center flex-col'>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/terms-conditions' element={<TermsandConditions />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
export default App;
