import React from 'react';
const Pricing = ({id}) => {
    const PricingWhite = require('../assets/images/PriceingWhite.png');
    const BlueCheck = require('../assets/images/BlueCheck.png');
    const PricingGem = require('../assets/images/PricingGem.png');
    const PriceGrowth = require('../assets/images/PriceGrowth.png');
    return (
        <div id={id} className='w-[80%] mt-10 flex flex-row justify-center items-center sm:flex-row mb-28'>
            <div className='flex flex-col justify-center items-center'>
                <div className='text-xs text-blue pb-3 font-semibold'>
                    PRICING
                </div>
                <h1 className='text-5xl font-semibold pb-5 flex text-center  text-custom-gray-light'>More Value Than Cost</h1>
                <div className="flex flex-col sm:w-[75%] w-full md:flex-row justify-center items-start md:space-x-8">
                    <div className="bg-white shadow-lg rounded-2xl p-2 w-full md:w-2/6">
                        <img src={PricingWhite} className='p-2 w-10 rounded-lg shadow-md' alt='' />
                        <h2 className="text-3xl mt-5 font-semibold text-blue">Start</h2>
                        <p className="mt-4 text-gray-400 pb-4">Unleash the Power of Your Business with STARTR Plan.</p>
                        <div className="mt-4 text-4xl font-bold text-blue border-b-[1px] pb-7 border-blue">
                        $29 <span className="text-base text-gray-400 font-normal">per month</span>
                        </div>
                        <ul className="mt-4">
                            <li className="flex items-start mt-8 mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Automated fraud detection for Shopify stores</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Real-time monitoring of discount code usage</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Automatic order cancellation and refund for suspicious orders</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Easy integration with your existing Shopify setup</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Comprehensive reporting and analytics</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">1,000 orders a month</span>
                            </li>
                        </ul>
                        <button className="mt-6 w-full px-6 py-3 border border-blue text-blue rounded-lg hover:bg-purple-100">Get Started</button>
                    </div>
                    <div className="bg-white shadow-lg rounded-2xl p-2 w-full md:w-2/6 mt-8 md:mt-0">
                        <img src={PriceGrowth} className='p-2 w-10 rounded-lg shadow-md' alt='' />
                        <h2 className="text-3xl mt-5 font-semibold text-blue">Growth</h2>
                        <p className="mt-4 text-gray-400 pb-4">Unlock the potential with Growth Plan.</p>
                        <div className="mt-4 text-4xl font-bold text-blue border-b-[1px] pb-7 border-blue">
                        $49 <span className="text-base text-gray-400 font-normal">per month</span>
                        </div>
                        <ul className="mt-4">
                            <li className="flex items-start mt-8 mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Automated fraud detection for Shopify stores</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Real-time monitoring of discount code usage</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Automatic order cancellation and refund for suspicious orders</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Easy integration with your existing Shopify setup</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Comprehensive reporting and analytics</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">2,500 orders a month</span>
                            </li>
                        </ul>
                        <button className="mt-6 w-full px-6 py-3 border border-blue text-blue rounded-lg hover:bg-purple-100">Get Started</button>
                    </div>
                    <div className="bg-white shadow-lg rounded-2xl p-2 w-full md:w-2/6 mt-8 md:mt-0">
                        <img src={PricingGem} className='p-2 w-10 rounded-lg shadow-md' alt='' />
                        <h2 className="text-3xl mt-5 font-semibold text-blue">Pro</h2>
                        <p className="mt-4 text-gray-400 pb-4">Take Your Business to the Next Level with PRO Plan.</p>
                        <div className="mt-4 text-4xl font-bold text-blue border-b-[1px] pb-7 border-blue">
                        $69 <span className="text-base text-gray-400 font-normal">per month</span>
                        </div>
                        <ul className="mt-4">
                            <li className="flex items-start mt-8 mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Automated fraud detection for Shopify stores</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Real-time monitoring of discount code usage</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Automatic order cancellation and refund for suspicious orders</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Easy integration with your existing Shopify setup</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">Comprehensive reporting and analytics</span>
                            </li>
                            <li className="flex items-start mb-4">
                                <img className="w-5" src={BlueCheck} alt='' />
                                <span className="ml-2 text-blue">over 5,000 orders a month</span>
                            </li>
                        </ul>
                        <button className="mt-6 w-full px-6 py-3 border border-blue text-blue rounded-lg hover:bg-purple-100">Get Started</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Pricing;