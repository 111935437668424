import React from "react";
import ButWhyUs from "../components/ButWhyUs";
import PreventDiscount from "../components/PreventDiscount";
import TheProblem from "../components/TheProblem";
import PowerfulSolution from "../components/PowerfulSolution";
import Testimonials from '../components/Testimonials';
import Pricing from '../components/Pricing';
// import Faq from '../components/Faq';
const HomePage = () => {
  return (
    <>
      <PreventDiscount id="prevent-discount"/>
      <TheProblem id="the-problem"/>
      <PowerfulSolution id="powerful-solution"/>
      <ButWhyUs id="but-why-us"/>
      <Testimonials id="testimonials"/>
      <Pricing id="pricing"/>
      {/* <Faq id="faq"/> */}
    </>
  );
};
export default HomePage;
