import React from 'react';
const ButWhyUs = ({id}) => {
    const Price = require('../assets/images/price.png');
    const Support = require('../assets/images/support.png');
    const Use = require('../assets/images/use.png');
    return (
        <div id={id} className="relative sm:w-[80%] w-full flex flex-col items-center bg-black sm:rounded-3xl p-6 pb-10 text-white overflow-hidden">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-40 w-80 h-90 flex justify-center">
                <div className="absolute w-80 h-40 bg-transparent rounded-b-full shadow-2xl" style={{
                    boxShadow: '0 110px 170px #4C53FF, 0 60px 120px #4C53FF'
                }}></div>
            </div>
            <h2 className="md:text-5xl text-2xl text-center font-semibold my-4 z-10 pb-3">Why Choose CustomerGenius?</h2>
            <p className="pb-6 w-[80%] flex text-center  border-b-2 border-custom-gray">
                Protect your revenue and maintain the integrity of your discount codes without manual intervention. CustomerGenius works 24/7 to safeguard your business, allowing you to focus on growth instead of fraud management.
            </p>
            <div className="flex sm:flex-row flex-col items-center mt-6 w-[85%] justify-around">
                <button className="relative sm:w-[26%] border-[0.1px] border-gray-400 w-full sm:mb-0 mb-5 flex items-center text-white py-3 px-6 rounded-xl bg-custom-blue shadow-price shadow-inset-left-right hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-custom-blue focus:ring-opacity-50">
                    <img className='w-8 mr-2' src={Price} alt='price' />
                    <div>Affordable Price</div>
                </button>
                <button className="relative sm:w-[26%] border-[0.1px] border-gray-400 w-full sm:mb-0 mb-5 flex items-center text-white py-3 px-6 rounded-xl bg-custom-blue shadow-support shadow-inset-left-right hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-custom-blue focus:ring-opacity-50">
                    <img className='w-8 mr-2' src={Support} alt='support' />
                    <div>Reliable Support</div>
                </button>
                <button className="relative sm:w-[26%] border-[0.1px] border-gray-400 w-full sm:mb-0 mb-5 flex items-center text-white py-3 px-6 rounded-xl bg-custom-blue shadow-use shadow-inset-left-right hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-custom-blue focus:ring-opacity-50">
                    <img className='w-8 mr-2' src={Use} alt='use' />
                    <div>Easy-to-use</div>
                </button>
            </div>
        </div>
    )
}
export default ButWhyUs;