import React from 'react';

const Footer = () => {
  const FooterLogo = require('../assets/images/FooterLogo.png');
  const Email = require('../assets/images/Email.png');
  
  return (
    <div className='w-[80%] mb-6 border-t-2 pt-10'>
      <div className='flex items-center justify-between sm:flex-row flex-col'>
        <img className='sm:w-[18%] w-[60%] mb-10 sm:mb-8' src={FooterLogo} alt="CustomerGenius" />
        <div className='flex justify-between'>
          <div className='flex mb-10'>
            <img className='sm:w-5 w-15 mr-2' src={Email} alt="Mail" />
            <a className='text-sm text-gray-500' href="mailto:support@customergenius.io">support@customergenius.io</a>
          </div>
        </div>
      </div>
      <div className='border-t-2 flex justify-between items-center sm:flex-row flex-col'>
        <div className='text-sm text-gray-500 pt-6'>Copyright &copy; 2024 CustomerGenius</div>
        <div className='text-sm flex text-gray-500 sm:flex-row items-center flex-col sm:pt-6 pt-3 mb-1 '>All Rights Reserved
          <div className='text-sm  text-gray-500 mx-1 hidden sm:block'> | </div>
          <div className='flex sm:mt-0 mt-3 '>
            <a href='/terms-conditions' className='text-blue underline-offset-custom'>Terms and Conditions </a>
            <div className='text-sm  text-gray-500 mx-1'> | </div>
            <a href='/privacy-policy' className='text-blue underline-offset-custom'> Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer;
