/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import HeaderLogo from '../assets/images/FooterLogo.png';
import { useLocation, useNavigate } from 'react-router-dom';
const Menu = [
  {
    id:1,
    menuItem:"Solutions",
    href:"powerful-solution"
  },
  {
    id:2,
    menuItem:"Features",
    href:"but-why-us"
  },
  {
    id:3,
    menuItem:"Feedback",
    href:"testimonials",
  },
  {
    id:4,
    menuItem:"Pricing",
    href:"pricing"
  },
  // {
  //   id:5,
  //   menuItem:"FAQ",
  //   href:"faq"
  // },
]
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { hash } = useLocation();


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable body scroll
    } else {
      document.body.style.overflow = 'auto'; // Enable body scroll
    }
    // Cleanup effect on component unmount
    return () => {
      document.body.style.overflow = 'auto'; // Ensure scroll is enabled if component unmounts
    };
  }, [isOpen]);
  const scrollToSection = (id) => {
    navigate(`/#${id}`)
  };
  const handalmobilemenu = () => {
    if (window.innerWidth > 991) {
      setIsOpen(false);
      document.body.style.overflow = "scroll";
    }
  }

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);
  
  useEffect(() => {
    window.addEventListener("resize", handalmobilemenu)
  }, [isOpen])
  return (
    <div className='sm:w-[80%]  w-full h-24 sticky top-0 z-20'>
      <header className="bg-white text-black shadow-navcustom rounded-3xl">
        <nav className="flex items-center justify-between p-4">
            <a href="/" className="text-2xl font-bold">
              <img className='w-[65%]' src={HeaderLogo} alt="" />
            </a>
            <div id="menu" className={`hidden lg:flex space-x-4 font-normal text-xl`}>
              {
                Menu.map((item)=>{
                  return(
                    <button key={item.id} onClick={()=>scrollToSection(item.href)} className="hover:border-b-2 hover:border-black px-3 py-2 border-b-2 border-transparent text-gray-600">{item.menuItem}</button>
                  )
                })
              }
            </div>
            <div>
              <button className='bg-gradient-to-r from-blue via-[#A9A6FF] to-blue text-white font-semibold xl:py-4 py-3 xl:px-8 px-4 rounded-xl'>
              Try Now
              </button>
              <button id="menu-toggle" className="lg:hidden px-2 py-1 border border-white rounded ml-5" onClick={toggleMenu}>
                {
                  isOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  :
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                  </svg>
                }
              </button>
            </div>
        </nav>
            <div className='lg:hidden'>
            <div id="menu" className={`${isOpen ? 'flex flex-col absolute top-[90px] left-0 h-screen bg-white w-full ease-in-out duration-500 font-normal text-xl':'hidden'}`}>
              {
                Menu.map((item)=>{
                  return(
                    <button key={item.id} onClick={()=>scrollToSection(item.href)} className="ps-10 py-2 border-b-2 border-transparent w-10">{item.menuItem}</button>
                  )
                })
              }
            </div>
            </div>
    </header>
    </div>
  )
}
export default Header;
