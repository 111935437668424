import React from "react";
export default function PreventDiscount({id}) {
  return (
    <div id={id} className="w-[80%] pt-24 flex items-center justify-center flex-col ">
      {/* <div className="max-w-[694px] text-center pb-20 rounded-[100%] border-b-2 border-gradient-to-r from-cyan-500 to-blue-500"> */}
      <div className="max-w-[800px] text-center pb-20 rounded-[100%] border-b-2">
      <div className="text-blue font-bold text-sm">
        CustomerGenius
      </div>
      <div className="text-3xl lg:text-5xl text-custom-gray-light font-bold !leading-tight">
      Protect Your Shopify Store from Discount Code Abuse with <span className="px-3 bg-gradient-to-r from-blue to-[#9483FF] rounded-xl text-white">CustomerGenius</span>
      </div>
      <div className="hidden lg:block text-[#777A7C] text-sm font-normal pt-4 lg:pt-4">Automated Fraud Detection and Prevention for Smarter E-commerce</div>
      <div className="lg:hidden text-[#777A7C] text-sm font-normal pt-4 lg:pt-4 max-w-[300px] mx-auto">Automated Fraud Detection and Prevention for Smarter E-commerce</div>
      </div>
    </div>
  );
}
